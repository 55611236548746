.on-postcard-swiper .swiper-slide {
    // height: 0vw;
    // padding-bottom: 56.25%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    // & img {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     margin: 0 auto;
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }
}

.fullscreen-swiper {
  position: relative;
  display: block;
  z-index: 99;
  width: 100vw;
  overflow: hidden;
  & .swiper-button-prev {
    left: 0;
    z-index: 20;
  }
  & .swiper-button-next {
    right: 0;
    z-index: 20;
  }
  & .image-swiper {
    object-fit: cover;
    max-width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 90vh;
    & img, & video {
      height: 100%;
      max-width: 90%;
      max-height: 90vh;
      object-fit: contain;
    }
    & .video-responsive, & .video-container {
      @media screen and (min-width: 768px){
        width: calc(100% - 120px);
        height: 100%;
        margin: 0 auto;
        padding-bottom: 0;
      }
    }
    & .bg {
      height: 100%;
      background-size: contain;
      max-width: calc(100% - 120px);
      margin: 0 auto;
      right: 0;
    }
  }
  @media screen and (max-width: 768px){
      height: 100vh;
  }

  video {
    width: initial !important;
    border-radius: 0.5rem;
  }
}

.container-is-mobile{
  @media screen and (max-width: 500px) {
    width: 111%;
    left: -5.5%;
  }
}

.one-image {
  max-height: 90vh;

  @media screen and (max-width: 768px){
    position: relative;
    display: block;
    height: 100%;
    max-width: 90%;
    max-height: 70vh;
    object-fit: contain;
  }
}

.video-swiper {
  @media screen and (max-width: 768px){
    height: 100% !important;
    min-height: 360px;
  }
}
.video-responsive, .video-container {
  @media screen and (max-width: 768px){
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    width: 100% !important;
  }
}