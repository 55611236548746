.cb-media {
  .swiper-slide {
    height: 0vw;
    padding-bottom: 56.25%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .bg,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 768px) {
    .swiper-slide {
      height: 0vw;
      padding-bottom: 70%;
    }
  }

  .thumbs-swiper {
    .swiper-slide {
      height: 0vw;
      padding-bottom: 22%;
      cursor: pointer;
      transition: all 0.3s;
      border: solid 1px transparent;

      .bg {
        background-size: cover;
      }
    }

    .swiper-slide-thumb-active {
      border: solid 1px var(--yellow);
    }
  }

  @media screen and (max-width: 768px) {
    .thumbs-swiper {
      .swiper-slide {
        height: 22vw;
        padding-bottom: 0;
        width: 75px !important;
        margin-right: 15px !important;
      }
    }
  }

  .main-swapper {
    .swiper-slide-active {
      width: 100% !important;
    }
  }

  @media (max-width: 767px) {
    .swiper-slide {
      height: 333px;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 60px;
  height: 60px;
}

.swiper-button-next,
.swiper-button-prev {
  width: 60px;
  height: 60px;
}

.swiper-button-prev {
  transform: scaleX(-1);
}

.publication-fullscreen-swiper {
  position: relative;
  display: block;
  z-index: 99;
  width: 100vw;
  overflow: hidden;
  & img {
    height: 100%;
    max-width: 90%;
    max-height: 90vh;
    object-fit: contain;
  }
  & .swiper-button-prev {
    left: 0;
    z-index: 20;
  }
  & .swiper-button-next {
    right: 0;
    z-index: 20;
  }
  & .swiper-slide {
    height: auto;
    padding-bottom: 0;
    & .video-responsive {
      @media screen and (min-width: 768px) {
        width: calc(100% - 120px);
        height: 100%;
        margin: 0 auto;
        padding-bottom: 0;
      }
    }
    & .bg {
      height: 100%;
      background-size: contain;
      max-width: calc(100% - 120px);
      margin: 0 auto;
      right: 0;
    }
  }
  // @media screen and (max-width: 768px){
  //     height: 100vh;
  // }
}

.video-responsive {
  @media screen and (max-width: 768px) {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    width: 100% !important;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: '';
  background: url(../../../assets/img/icons/arrow-right-circle.svg);
  width: 60px;
  height: 60px;
  background-size: 100%;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}
