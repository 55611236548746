.cb-phone-input {
  .react-tel-input {
    .form-control {
      @apply ring-0 w-full rounded-none text-white-secondary text-sm;
      border-top: none !important;
      border-left: none !important;
      padding-top: 10px;
      padding-bottom: 10px;
      height: unset !important;
      border-right: none !important;
      background-color: inherit !important;
      border-bottom: 0.5px solid #c4c4c4 !important;

      &.focus-visible {
        border-bottom: 0.5px solid #c4c4c4 !important;
      }
    }

    .selected-flag {
      @apply mr-1;
      background-color: #1a1a1a !important;
    }

    .flag-dropdown {
      border: none !important;
      border-bottom: 0.5px solid #c4c4c4 !important;
      background-color: #1a1a1a !important;
    }
  }

  
  &.error {
    .react-tel-input {
      .form-control {
        @apply ring-0;
        border-bottom: 0.5px solid var(--yellow-dark) !important;
      }
      .flag-dropdown {
        border-bottom: 0.5px solid var(--yellow-dark) !important;
      }
    }
  }
}

.react-tel-input .country-list {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  overflow: auto !important;
  max-width: 250px !important;
  background-color: #424242;
}

.react-tel-input .country-list li {
  padding: 8px 15px !important;
  font-family: "Manrope", sans-serif !important;
  transition: all 0.3s !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.03) !important;
  overflow: auto !important;
}

.react-tel-input .country-list .country-name {
  font-size: 12px !important;
  color: white;
}

.react-tel-input .country-list .country .dial-code {
  font-size: 12px !important;
  font-weight: bold !important;
  color: white !important;
}

.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background: rgba(0, 0, 0, 0.02) !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #1a1a1a;
}

.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #f7a709;
}

.react-tel-input .selected-flag .arrow.up {
  border-bottom: 4px solid #f7a709;
}
