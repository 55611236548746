.cb-mobile-menu {
  bottom: 52px;
  top: 0px;

  .highlighted-btn {
    border-radius: 60px;
  }

  .cb-icon-dashboard {
    rect,
    path {
      @apply fill-current text-yellow;
    }
  }
}
