@charset "utf-8";

@import 'tailwindcss/utilities';
@import 'tailwindcss/base';
@import 'tailwindcss/components';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700;800&display=swap');

body {
  font-family: 'Manrope', sans-serif !important;
  color: white !important;
  background-color: #0e0e0e;
}

#root {
  background-color: #0e0e0e;
}

.wrapper-lg {
  width: 1280px;
  max-width: 90%;
  margin: 0px auto;
  position: relative;
}

.wrapper-md {
  width: 945px;
  max-width: 90%;
  margin: 0px auto;
  position: relative;
}

.wrapper-sidebar {
  @apply px-20 pt-9;
  // max-width: 90%;
  position: relative;
}

:root {
  --yellow: #f7a70b;
  --yellow-dark: #f7a70b;
  --gray-dark: #4b4b4b;
  --white-secondary: #dcdcdc;
  --toastify-icon-color-success: #f7a70b;
  --toastify-text-color-dark: #dcdcdc;
}

.cb-button.cb-button-primary:hover {
  --tw-shadow: 0px 0px 14px rgba(252, 168, 1, 0.2);
}

.App {
  @apply overflow-hidden;
}

// Input with icon

.input-with-icon {
  @apply relative;

  & .cb-icon {
    @apply absolute bottom-0;
    right: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-shadow: none !important;
  --tw-ring-color: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// Select XS

.select-xs {
  margin-top: -1px;

  .MuiSelect-select.MuiSelect-select {
    font-size: 12px !important;
    padding: 0 20px 0 0 !important;
    color: #c4c4c4 !important;
    font-family: 'Manrope', sans-serif !important;
    letter-spacing: -0.1px;
    background: transparent !important;
  }

  .cb-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;
  }

  .MuiInput-underline:before {
    width: calc(100% - 20px);
    bottom: 2px;
  }

  .MuiInput-underline:after {
    display: none;
  }
}

.MuiPaper-elevation8 {
  --tw-shadow: 0px 4px 24px rgba(179, 179, 179, 0.4);
}

.MuiMenuItem-root {
  font-size: 12px !important;
  padding: 7px 15px !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: rgba(226, 51, 23, 0.1) !important;
  color: var(--yellow) !important;
}

.MuiCircularProgress-circleIndeterminate {
  stroke: var(--yellow) !important;
}

label:not(.textarea) {
  @apply font-semibold text-base tracking-tight;
}

label.required:after {
  content: ' *';
  @apply text-yellow;
}

.overview-sidebar {
  @apply pt-14;

  & .overview-sidebar-item {
    @apply mb-7;
  }

  h2 {
    min-height: 32px;
  }
}

.job-sidebar {
  @apply pt-14;

  & .job-sidebar-item {
    @apply mb-7;
  }

  h2 {
    min-height: 32px;
  }
}

.MuiTooltip-tooltip {
  font-family: 'Manrope', sans-serif !important;
}

#iframe:active,
#iframe:focus {
  border: none;
  outline: none;
}

button:focus {
  outline-width: 1px !important;
  outline-style: solid !important;
  // outline-color: var(--yellow) !important;
  outline-color: transparent !important;
}

textarea {
  resize: none !important;
}

.cb-post-input .emoji-picker {
  z-index: 1 !important;
}

.emoji-mart-bar {
  display: none !important;
}

.emoji-mart {
  color: #fff !important;
  border: 1px solid #f7a709 !important;
  background: #222427 !important;
}

@media only screen and (max-width: 640px) {
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: calc(48px + env(safe-area-inset-bottom)) !important;
    width: 100vw !important;
  }

  .Toastify__toast {
    border-radius: 0px !important;
    margin-bottom: 4px !important;
  }
}

.emoji-mart-category-label span {
  background-color: #222427 !important;
}

.emoji-mart-search input {
  background-color: #4b4b4b;
}

.swiper-pagination-bullets {
  order: 2;
  margin: 10px auto 5px;
}

.swiper-dot {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid var(--yellow);
  margin: 3px;
  border-radius: 50%;
  &.swiper-pagination-bullet-active {
    background-color: var(--yellow);
  }
  background-color: transparent;
}

.fullscreen-swiper .swiper-pagination-bullets {
  margin: 20px auto 0px;
}
.fullscreen-swiper .swiper-dot {
  width: 12px;
  height: 12px;
  border: 2px solid var(--yellow);
  margin: 5px;
}

.swiper-slide {
  background: var(--gray-dark);
}

.MuiBadge-badge {
  @apply bg-yellow text-gray-light;
  font-size: 10px !important;
  min-width: 18px !important;
  height: 18px !important;
}

.clamp-lines__button {
  @apply text-yellow;
}

.str-chat__loading-channels {
  background-color: #3636365f !important;
}
.str-chat__channel-preview-messenger--last-message {
  color: #d6d6d6;
}
