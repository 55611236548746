.cb-slider {
  .MuiSlider-rail {
    @apply bg-silver;
  }
  .MuiSlider-track {
    @apply bg-yellow;
  }
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }
  .MuiSlider-thumb {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    margin-top: -7px !important;
    @apply w-4 h-4 shadow-m;
  }
  &.yellowDot .MuiSlider-thumb {
    @apply bg-yellow;
  }
  &.hideLabel .PrivateValueLabel-label-5 {
    display: none;
  }
  .MuiSlider-valueLabel {
    left: -50% !important;
  }
  .MuiSlider-valueLabel {
    span {
      @apply bg-yellow;
    }
  }
}
