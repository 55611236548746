.cb-signin-methods {
  border-bottom: 1px solid #c4c4c4;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;

  .cb-icon {
    @apply flex items-center justify-center rounded-full cursor-pointer transition-all duration-300;
    border: 1px solid #c4c4c4;

    &:hover {
      @apply border-yellow;
    }
  }

  & a {
    @apply text-yellow underline;

    &:hover {
      @apply no-underline;
    }
  }

  & p {
    line-height: 0.875rem;
  }
}
