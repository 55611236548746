.cb-icon {
  // height: fit-content;
  width: fit-content;
  &.rounded {
    border-radius: 9999px !important;
    @apply shadow-m p-2;
  }

  svg * {
    transition: 0.3s;
  }

  &.loading svg circle {
    stroke: var(--yellow);
    opacity: 0.2 !important;
  }

  &.loading svg path {
    fill: var(--yellow);
  }
  &.active path {
    fill: white;
    stroke: white;
  }

  &.disabled svg * {
    fill: #b3b3b3 !important;
  }
}
