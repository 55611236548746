.personal-info-card {
  @apply rounded-lg w-full md:max-w-xl mb-8 pb-4 shadow-black;
}

.flex-row{
  label{
    @apply text-white-secondary;
  }
}

.grid-data {
  .MuiInput-underline:after {
    border: none !important;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    content: '';
    left: unset;
  }
  @apply grid gap-x-2;

  grid-template-columns: max-content 1fr;

  label {
    @apply flex items-center text-white-secondary tracking-tight text-sm;
  }

  input {
    @apply text-gray text-sm;
  }

  .cb-divider {
    grid-column: 1 / 5; /* this code makes the row stretch to entire width of the container */
  }

  .cb-select .MuiInput-input {
    @apply text-gray text-sm font-light;
  }

  .cb-location-container {
    height: 100%;

    .cb-location__control {
      border-width: 0 !important;
      height: 100%;

      .cb-location__indicators {
        display: none;
      }
    }
  }
}
