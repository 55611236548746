.update-password {
  @apply rounded-lg max-w-xl mb-8 pb-4 bg-black-light ;

  .MuiInput-underline:after {
    border: none !important;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    content: '';
    left: unset;
  }

  .grid-data {
    @apply grid gap-x-2;

    grid-template-columns: max-content 1fr;

    label {
      @apply flex items-center text-white-secondary tracking-tight text-sm;
    }

    input {
      @apply text-gray text-sm;
    }
  }

  .cb-divider {
    grid-column: 1 / 5; /* this code makes the row stretch to entire width of the container */
  }
}
