.cb-search-bar {
  @apply flex items-center;

  & .search-input {
    @apply z-10;
  }

  & input {
    @apply sm:rounded-md md:rounded-lg border-none shadow-search h-11 text-xs pl-8 md:pl-12 focus:ring-0;
  }

  &.dropdown-open input {
    @apply shadow-none;
  }

  & .cb-icon.cb-icon-search {
    @apply absolute top-2/4 left-2.5 md:left-5 -translate-y-1/2 transform w-3;
  }

  & .PrivateSwitchBase-root-1{
    padding: 0.5rem;
  }

  .cb-radio {
    .icon{
      width: 1rem;
      height: 1rem
    }

    .icon::after{
      width: 0.25rem;
      height: 0.25rem;
    }
  }

  .MuiTypography-root {
    @apply text-xs text-white-secondary leading-6 font-normal;
  }

  @media screen and (max-width: 480px) {
  
    & input::-webkit-input-placeholder {
      max-width: calc(100% - 180px);
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }

    & input::-moz-placeholder {
      max-width: calc(100% - 180px);
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }
    
    & input:placeholder-shown {
      text-overflow: ellipsis;
    }
  }
 
}
