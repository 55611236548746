.cb-location {
  .cb-location__dropdown-indicator {
    display: none;
  }

  &.secondary {
    .cb-location-container {
      .cb-location__control--is-focused,
      .cb-location__control--menu-is-open,
      .cb-location__control,
      .cb-location__input,
      .cb-location__control:hover {
        @apply border-gray shadow-none ring-0;

        input {
          @apply border-gray shadow-none ring-0;
        }
      }
    }
  }

  .cb-location-container {
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
      @apply border-yellow;
    }

    .cb-location__indicator-separator {
      @apply invisible;
    }

    .cb-location__menu {
      width: 95%;
      left: 50%;
      transform: translate(-50%);
      @apply text-sm;

      .cb-location__option {
        @apply hover:bg-black-light;
      }
      .cb-location__option--is-selected {
        @apply text-yellow;
      }
    }

    .cb-location__indicator {
      path {
        fill: #ff542c;
      }
    }

    .cb-location__placeholder {
      @apply text-sm text-gray;
    }

    .cb-location__single-value {
      @apply text-sm;
    }

    .cb-location__control--is-focused,
    .cb-location__control--menu-is-open,
    .cb-location__control,
    .cb-location__input,
    .cb-location__control:hover {
      @apply border-l-0 border-r-0 border-t-0 rounded-none shadow-none ring-0 text-sm;

      input {
        @apply border-gray shadow-none ring-0;
      }
    }
  }

  &.error {
    .cb-location-container {
      .cb-location__control--is-focused,
      .cb-location__control--menu-is-open,
      .cb-location__control,
      .cb-location__input,
      .cb-location__control:hover {
        @apply border-yellow-dark shadow-none ring-0;
      }
    }
  }
}
