.cb-chip {
  @apply text-xs uppercase relative;
  border: 1px solid;
  border-radius: 60px;
  padding: 5px 15px;
  font-weight: bold;
  letter-spacing: 0;
  white-space: nowrap;

  &.yellow-rent {
    color: #74afff;
    border-color: #74afff;
  }

  .max-w-75p {
    max-width: 75%;
  }

  .cb-icon.rounded {
    @apply p-0 absolute ml-1 md:ml-0 md:left-auto -top-2 -right-3;
    background-color: #74afff;

    @media screen and (max-width: 768px) {
      width: 18px !important;
      height: 18px !important;
      top: -6px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0px 10px;
    margin-left: 0;
    margin-right: 4px;
    font-size: 8px;
  }

  &.size-sm {
    font-size: 7px;
    padding: 0px 8px;
  }
}
