.auth-main {
  @apply min-h-screen py-5 px-5 sm:py-14 sm:px-20 w-full;

  @media screen and (min-width: 1024px) {
    width: 570px;
  }

  & > div {
    @apply rounded-xl py-10 px-10 sm:py-14 sm:px-20;

    .App {
      overflow: visible !important;
    }
  }
}
