.cb-back-button {
  @apply flex items-center text-yellow text-sm tracking-tight whitespace-nowrap;

  p {
    @apply ml-2 font-semibold;
  }

  .cb-icon {
    @apply transform -translate-x-0 transition-all;
  }

  &.light {
    p {
      @apply font-thin;
    }
  }

  &:hover {
    .cb-icon {
      @apply -translate-x-1;
    }
  }
}
