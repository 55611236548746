.cb-likes {
  @apply relative flex items-center overflow-visible;

  .popover {
    @apply hidden absolute bottom-full rounded-lg border border-yellow p-2 left-1/2 transform -translate-x-1/2;

    :before {
      position: absolute;
      z-index: -1;
      content: '';
      right: 10px;
      bottom: -6px;
      background-color: black;
      width: 10px;
      height: 10px;
      border-right: solid 1px var(--yellow);
      border-bottom: solid 1px var(--yellow);
      transform: rotate(60deg) skewX(30deg) scaleY(0.866);
    }
  }

  &:hover {
    .popover {
      display: block;
    }
  }
}
