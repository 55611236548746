.cb-mobile-filters {
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  pointer-events: none;
  @apply fixed bg-white w-screen z-20 overflow-auto top-full rounded-t-3xl;

  &.active {
    top: calc(103px + env(safe-area-inset-top));
    opacity: 1;
    pointer-events: auto;
    height: calc(100vh - 150px - env(safe-area-inset-bottom));
    padding-bottom: 100px;
    box-shadow: 0 -50px 0 50px rgba(0, 0, 0, 0.5);
  }
}
