header {
  .item {
    @apply flex items-center px-4 cursor-pointer first:pl-0;

    span {
      @apply font-semibold transition-all duration-300 text-xs;
    }

    &:hover span,
    &.active span {
      @apply text-yellow;
    }
    &:hover #social-button,
    &.social-active span {
      @apply text-white;
    }

    .icon-open {
      transform: rotate(180deg);
    }
  }

  .highlight-btn {
    border-radius: 60px;
    @apply h-7 px-4 text-black bg-yellow-light text-xs font-semibold flex items-center transition-all duration-300;

    &:hover {
      box-shadow: 0 0 0 1px var(--yellow) inset;
    }

    &.active {
      @apply text-white;
    }
  }

  .publish-button {
    width: 160px !important;
  }
}
