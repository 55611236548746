.cb-checkbox {
  @apply inline-flex items-center cursor-pointer;

  & input {
    @apply h-5 w-5 text-yellow rounded-xs text-sm focus:ring-0 shadow-none border-silver bg-transparent;
  }

  &.cb-checkbox-size-sm input {
    @apply h-4 w-4;
  }

  & span {
    @apply text-white-secondary ml-3 text-sm transition-colors duration-300;
  }

  &:hover span {
    @apply text-yellow;
  }

  &.cb-checkbox-size-sm span {
    @apply text-2xs ml-2;
  }
}
