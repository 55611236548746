.cb-navbar {
  transition: 0.3s all;
  z-index: 1000;
  .cb-icon:not(.active) {
    rect,
    path {
      @apply fill-current text-gray;
      stroke: none;
    }
  }
  .cb-icon.active {
    rect,
    path {
      @apply fill-current text-yellow;
      stroke: none;
      color: var(--yellow);
    }
  }

  &.hide {
    transform: translateY(52px);
    transition: 0.3s all;
  }
}
