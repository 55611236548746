.cb-button {
  outline-color: #f7a70b !important;
  @apply cursor-pointer w-full text-center transition duration-300 flex items-center justify-center;

  &.w-min {
    width: min-content;
  }

  &.cb-button-primary {
    @apply bg-yellow shadow-button text-gray-light;

    &:hover {
      @apply shadow-button-hover text-black;
    }
  }

  &.cb-button-link {
    height: unset !important;
    outline: none !important;
    @apply bg-transparent text-yellow shadow-none w-auto;
  }

  &.cb-button-outline {
    @apply bg-transparent border border-yellow text-yellow shadow-none;

    &:hover {
      @apply bg-yellow bg-opacity-10;
    }
  }

  &.cb-button-text {
    width: fit-content !important;
    @apply bg-transparent text-yellow shadow-none;

    &.disabled {
      @apply text-gray-light;
    }
  }

  &.cb-button-size-sm:not(.rounded-full) {
    @apply h-9 text-xs rounded-sm;
  }

  &.cb-button-size-md:not(.rounded-full) {
    @apply h-11 text-sm rounded-md;
  }

  &.cb-button-size-lg:not(.rounded-full) {
    @apply h-14 text-base rounded-lg;
  }
}
