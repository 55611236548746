.cb-text-group {
  input:focus {
    outline: none !important;
    --tw-ring: none !important;
    --tw-ring-shadow: none !important;
    @apply ring-0 border-silver;
  }
  input {
    @apply text-base text-gray px-4 py-1.5 tracking-tighter font-normal;
  }
}
