.cb-config-table {
  .config-table-cell {
    background: #515151;

    & > span:first-child {
      color: #a3a3a3;
      margin-right: 5px;
    }
  }
  .config-table-cell:nth-child(4n + 3),
  .config-table-cell:nth-child(4n + 4) {
    background: transparent;
  }
  .config-table-cell:nth-child(2n + 1) {
    border-right: solid 1px #c4c4c4;
  }

  @media (max-width: 768px) {
    .config-table-cell:nth-child(even) {
      background-color: transparent;
      border-right: none;
    }

    .config-table-cell:nth-child(odd) {
      background-color: #1a1a1a;
      border-right: none;
    }
  }
}
