.cb-radio {
  display: block !important;

  .MuiFormGroup-root {
    @apply flex-row;
  }

  .MuiTypography-root {
    @apply text-sm text-white-secondary leading-6 font-normal;
  }

  .icon {
    @apply w-6 h-6 border border-solid border-gray rounded-full;
  }

  .checked-icon {
    @apply border-yellow-dark bg-yellow flex items-center justify-center;

    &:after {
      content: '';
      @apply h-2.5 w-2.5 rounded-full bg-white;
    }
  }
}
