.cb-footer {
  h2 a::after {
    content: '';
    width: 100%;
    background-color: #f7a70b;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.5s;
    height: 2px;
  }

  h2:hover a::after {
    width: 0%;
  }

  @apply rounded-md pt-20 pb-6;

  .bolder {
    @apply uppercase text-sm leading-5 font-extrabold mb-7;
  }

  a {
    @apply transition duration-300;

    &:hover {
      @apply text-yellow;
    }
  }

  .subtitle {
    color: #828282;
    @apply text-xs leading-4;
  }
}
