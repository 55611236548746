.rdw-editor-main {
  height: calc(100% - 81px);

  .public-DraftEditor-content {
    caret-color: var(--yellow);
  }
}

@media (max-width: 767px) {
  .rdw-editor-main {
    height: calc(100% - 107px);
  }
}
