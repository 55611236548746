.cb-autocomplete {
  border: none;
  height: 36px !important;

  .MuiSelect-select:focus {
    @apply bg-black-light ;
  }

  .MuiFormLabel-root {
    @apply tracking-tight text-sm font-normal text-white-secondary -top-3;
  }

  .MuiAutocomplete-root {
    width: 100% !important;
  }

  .MuiIconButton-root {
    padding: 2px !important;
  }

  .MuiOutlinedInput-root,
  .MuiFormControl-root {
    height: 32px !important;
    padding-top: 0px !important;
    width: 100% !important;
    padding-bottom: 0px !important;
  }

  .MuiOutlinedInput-input,
  .MuiInputBase-input {
    height: 10px !important;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 4px) scale(0.75);
    @apply px-0.5 text-yellow;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid #c4c4c4 !important;
    height: 36px !important;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #f7a70b !important;
  }

  &.error {
    .MuiOutlinedInput-root,
    .MuiOutlinedInput-notchedOutline {
      border-color: #f7a70b !important;
    }
  }

  .cb-icon {
    @apply absolute right-4 bottom-2;
  }
}
