.PrivateValueLabel-thumb-5.PrivateValueLabel-open-6
  .PrivateValueLabel-offset-7 {
  transform: scale(1) translateY(0px) !important;
}

.cb-slider .MuiSlider-valueLabel span {
  background: transparent !important;
}

.cb-slider .MuiSlider-valueLabel span.PrivateValueLabel-label-9 {
  background: var(--yellow) !important;
  padding: 2px 5px;
  border-radius: 3px;
}
