.str-chat {
  height: calc(100vh - 160px) !important;
}

.str-chat * {
  font-family: 'Manrope', sans-serif !important;
}

.str-chat-channel-list,
.str-chat__channel-search,
.str-chat__channel-list-messenger {
  background: #363636 !important;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding-top: 0 !important;
  padding-right: 0 !important;
  background: #363636 !important;
}

.str-chat-channel-list {
  border-right: solid 1px #b3b1b180 !important;
  overflow: auto;
}

.str-chat__channel-search input {
  height: 44px !important;
  border-radius: 10px !important;
  border: solid 1px #b3b1b180 !important;
  background: #363636 !important;
  font-family: 'Manrope', sans-serif !important;
  font-size: 12px !important;
  transition: all 0.3s !important;
}

.str-chat__channel-search {
  padding-bottom: 20px !important;
  padding-top: calc(30px + env(safe-area-inset-top)) !important;
}

.str-chat-channel-list .str-chat__channel-list-messenger__main {
  padding: 0 !important;
}

.str-chat__channel-preview-messenger {
  padding: 15px 24px !important;
  margin: 0 !important;
  border-bottom: solid 1px black !important;
  box-shadow: none !important;
  position: relative !important;
  transition: all 0.3s !important;
}

.str-chat__channel-preview-messenger--active {
  background: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #000;
}

.str-chat__channel-preview-messenger--name {
  color: #fff;
}

.str-chat__channel-preview-messenger::before {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 2px !important;
  height: 0 !important;
  transition: all 0.3s !important;
  background: var(--yellow) !important;
}

.str-chat__channel-preview-messenger--active:before {
  height: 100% !important;
}

.str-chat__channel-search input:focus {
  border-color: black !important;
  box-shadow: none !important;
}

.str-chat__channel-preview-messenger--unread::after {
  position: absolute !important;
  top: 50% !important;
  transform: translate(-50%) !important;
  right: 24px !important;
  width: 7px !important;
  height: 7px !important;
  background: var(--yellow) !important;
  border-radius: 50% !important;
  content: '' !important;
}

.str-chat__channel-preview-messenger:hover {
  background: #363636 !important;
}

.str-chat__list {
  border-radius: 10px !important;
  border: solid 1px #b3b1b180 !important;
  margin: 0 auto !important;
  width: calc(100% - 24px * 2) !important;
  background-color: #363636 !important;
  background-image: url('/assets/img/bg-chat.png') !important;
  background-position: center !important;
  background-size: cover !important;
}

.str-chat__input-flat {
  padding: 5px 24px !important;
  background-color: #363636 !important;
  margin: 15px 0 !important;
}

.str-chat__input-flat .str-chat__textarea > textarea {
  background: #363636 !important;
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  padding: 5px 20px !important;
  transition: all 0.3s !important;
  line-height: 26px !important;
  border-radius: 100px !important;
  width: calc(100% - 60px) !important;
  margin: 0 auto !important;
  font-size: 12px !important;
  display: block !important;
  box-shadow: 0 0 0 1px transparent !important;
  border-radius: 10px !important;
  border: solid 1px #b3b1b180 !important;

  &::placeholder {
    color: white !important;
  }
}

.str-chat__input-flat-emojiselect {
  left: 0 !important;
  bottom: 6px !important;
  top: initial !important;
}

.str-chat__input-flat-emojiselect img,
.str-chat__input-flat-fileupload img {
  width: 22px !important;
  height: 22px !important;
}

.str-chat__input-flat .rfu-file-upload-button {
  bottom: 6px !important;
  right: 0 !important;
  top: initial !important;
}

.str-chat__input-flat .str-chat__textarea > textarea:focus {
  border-radius: 100px !important;
}

.str-chat__message p,
.str-chat__message-simple p {
  font-size: 12px !important;
}

.str-chat__date-separator div {
  font-size: 12px !important;
}

.str-chat__li--single .str-chat__message--me .str-chat__message-text-inner {
  padding: 10px !important;
}

.str-chat__input-flat--textarea-wrapper
  .str-chat__emojiselect-wrapper
  .str-chat__tooltip {
  bottom: 40px !important;
  left: 3px !important;
}

.str-chat__input-flat--textarea-wrapper
  .str-chat__fileupload-wrapper
  .str-chat__tooltip {
  right: 5px !important;
  bottom: 40px !important;
}

.rfu-file-previewer__file a {
  font-size: 12px !important;
}

.rfu-file-previewer__close-button {
  color: transparent !important;
  position: relative !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background: rgba(0, 0, 0, 0.05) !important;
  line-height: 19px !important;
}

.rfu-file-previewer__close-button::before {
  content: 'x' !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  text-align: center !important;
  color: #000 !important;
  font-size: 11px !important;
}

button.str-chat__channel-preview-messenger:focus {
  outline: none !important;
}

.str-chat__send-button {
  width: 20px !important;
}

.str-chat__send-button svg {
  position: absolute !important;
  bottom: 10px !important;
}

.str-chat__send-button svg path {
  fill: var(--yellow) !important;
}

.str-chat__message-notification {
  background: var(--yellow) !important;
  padding: 5px 15px !important;
}

.str-chat__channel-list-messenger__main > p {
  text-align: center !important;
  font-size: 12px !important;
}

.str-chat-channel-list.flat {
  @apply w-full border-0;
  border-right: none !important;

  .str-chat__channel-list-messenger__main {
    @apply p-0;

    .str-chat__channel-preview-messenger {
      @apply m-0;
    }

    .str-chat__channel-preview-messenger--active {
      box-shadow: none !important;
      border-radius: 0 !important;
      border-bottom: solid 1px #363636 !important;

      &::before {
        @apply hidden;
      }
    }
  }

  .str-chat__channel-list-messenger {
    @apply min-w-full w-full;
  }
}

.inline-text-emoji {
  font-size: 18px;
}

.channel-list-home .str-chat__channel-preview-messenger {
  padding-left: 0 !important;
}

.str-chat__message-simple__actions,
.str-chat__load-more-button {
  display: none !important;
}

.str-chat__message-text {
  & .str-chat__message-text-inner.str-chat__message-simple-text-inner * {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 768px) {
  .chat {
    margin-top: -90px;
    z-index: 1000;
    position: relative;
    left: calc(-5% - 2px) !important;
    width: 100vw;
    border-radius: 0 !important;
  }

  .str-chat-channel {
    max-height: initial;
    height: calc(100vh - 77px - env(safe-area-inset-bottom)) !important;
    border-radius: 0 !important;
  }

  .str-chat-channel.messaging .str-chat__main-panel {
    padding-left: 0;
    border-radius: 0 !important;
  }

  .str-chat__list {
    width: 95% !important;
  }

  .str-chat__input-flat {
    padding: 0 2.5% !important;
  }
}
