.cb-input {
  border: 0.5px solid #f7a70b;
  color: #DCDCDC;
  @apply rounded-md text-xs h-11 ring-0 w-full;

  &:focus {
    --tw-ring-shadow: none;
    border: 0.5px solid #f7a70b;
    @apply shadow-none;
  }

  &::placeholder {
    color: #f7a70b;
    @apply text-xs;
  }
}

.input-lowercase input {
  text-transform: lowercase;
}
.input-lowercase input::placeholder {
  text-transform: none;
}

// Material Inputs

.MuiInput-root {
  @apply w-full;

  & label.Mui-focused {
    color: green;
  }

  .MuiInputAdornment-root > * {
    color: var(--yellow);
  }

  &.error::before {
    border-bottom-color: var(--yellow-dark) !important;
    border-bottom-width: 0.5px !important;
  }

  &:not(.error)::before {
    border-bottom-color: #737373 !important;
    border-bottom-width: 1px !important;
  }

  &::after {
    border-bottom-color: var(--yellow) !important;
    border-bottom-width: 1px !important;
  }

  & .MuiInputLabel-formControl {
    transform: translate(0, 35px) scale(1);
    color: #DCDCDC;
    font-family: 'Manrope';
  }
  
  & .MuiInputLabel-shrink {
    transform: translate(0, 9px) scale(0.75);
  }
  & input {
    @apply focus:ring-0 text-sm;
    font-family: 'Manrope' !important;
    padding: 14px 10px;
    color: #DCDCDC;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: red;
    }
    &:hover fieldset {
      border-color: yellow;
    }
    &.Mui-focused fieldset {
      border-color: green;
    }
  }
}
