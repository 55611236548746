.cb-select {
  border: none;

  .MuiSelect-select:focus {
    @apply bg-black-light ;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 4px) scale(0.75);
    @apply px-0.5 text-yellow;
  }

  .MuiInput-input {
    @apply text-sm font-normal;
    padding: 14px 10px;
    font-family: 'Manrope' !important;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid #515151 !important;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #f7a70b !important;
  }

  &.error {
    .MuiOutlinedInput-root,
    .MuiOutlinedInput-notchedOutline {
      border-color: #f7a70b !important;
    }
  }

  &.border-none {
    .MuiOutlinedInput-input {
      color: rgba(179,179,179,1);
      padding-left: 10px;
      padding-right: 40px;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    &.error {
      .MuiOutlinedInput-root,
      .MuiOutlinedInput-notchedOutline {
        border-color: none !important;
      }
    }
  }

  label + .MuiInput-formControl {
    margin-top: 0px !important;
  }

  .label {
    padding-left: 10px;
    padding-right: 40px;
    font-family: 'Manrope' !important;
    color: #aeaeae;
    @apply text-sm font-normal absolute top-1/2 transform -translate-y-1/2;
  }

  .MuiFormLabel-filled {
    display: none !important;
  }

  .cb-icon {
    @apply absolute right-4 top-1/2;
    transform: translateY(-50%);
  }
}
