.react-player__preview {
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: contain !important;
}

.is-media .react-player__preview {
    background-size: cover !important;
}

.min-height-360 .react-player__preview {
    min-height: 360px;
}