.cb-categories-slide {
    & .swiper-container {
        overflow: visible !important;
    }

    .swiper-button-prev{
        left: -38px;
        margin-top: -35px
    }
    
    .swiper-button-next::after, .swiper-button-prev::after{
        width: 80px;
        height: 80px;
    }
    
    .swiper-button-next{
        right: -38px;
        margin-top: -35px
    }
    
    .swiper-button-next, .swiper-button-prev{
        width: 80px;
        height: 80px;
    }
}
