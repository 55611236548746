.cb-location-autocomplete {
  & .MuiInput-root {
    @apply focus:ring-0 text-sm;
    font-family: "Manrope" !important;
    padding: 12px 50px 12px 10px !important;
    color: #fff !important;

    .MuiInput-input {
      padding: 0 !important;
    }
  }

  & ul {
    background-color: #424242 !important;
    color: white;
  }

  & ul li{
    font-size: 12px;
    font-weight: 500;
  }

  .MuiTextField-root {
    &::before {
      border-bottom-color: #c4c4c4 !important;
      border-bottom-width: 1px !important;
    }

    & .MuiInputLabel-formControl {
      transform: translate(0, 35px) scale(1);
      color: #DCDCDC;
      font-family: "Manrope";
    }
    & .MuiInputLabel-shrink {
      transform: translate(0, 9px) scale(0.75);
    }
    & input {
      @apply focus:ring-0 text-sm;
      font-family: "Manrope" !important;
      padding: 14px 10px;
      color: #DCDCDC;
    }

    &.error {
      .MuiInput-root {
        &::before {
          border-bottom-color: var(--yellow-dark) !important;
          border-bottom-width: 0.5px !important;
        }

        &::after {
          border-bottom-color: var(--yellow) !important;
          border-bottom-width: 1px !important;
        }
      }
    }
  }

  .MuiSvgIcon-root path{
    fill: white;
  }

  & .MuiAutocomplete-popper {
    border-radius: 50px;
  }

  .css-6wwd73-MuiAutocomplete-noOptions{
    color:white;
    background-color: #424242;
    font-size: 12px;
    font-weight: 500;
  }
}
