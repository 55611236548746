.text-description * {
  background: transparent !important;
  font-size: inherit !important;
  font-family: inherit !important;
  color: #dcdcdc !important;
}

.text-description > * {
  // margin-bottom: 20px;
}

.text-description ol,
.text-description ul {
  list-style: disc;
  padding-left: 20px;
}
