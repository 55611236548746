.dashboard-sidebar {
  width: 270px;
  @apply h-screen fixed pt-8 px-9 pb-10 hidden md:flex flex-col justify-between max-h-screen overflow-y-auto z-20 bg-black-light ;

  @media screen and (max-width: 768px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  & .dashboard-sidebar-item {
    @apply py-1.5;

    & a {
      @apply flex items-center;

      & span {
        @apply ml-4 text-gray transition duration-300 text-sm md:text-sm;
      }

      &:hover {
        & span {
          @apply text-yellow;
        }
      }
    }

    &.disabled {
      @apply pointer-events-none text-gray;
      path {
        fill: #c4c4c4;
      }
      a > div:last-of-type {
        @apply ml-4;
        span {
          @apply ml-0;
        }
      }
    }
  }

  & .dashboard-sidebar-item-selected {
    & a {
      & span {
        @apply text-yellow;
      }
    }
  }

  & .logout {
    @apply flex mt-7 cursor-pointer items-center;

    & span {
      @apply ml-4 transition duration-300;
    }

    &:hover {
      & span {
        @apply text-yellow;
      }
    }
  }
}
