.personal-register {
  font-family: 'Manrope' !important;

  .cb-location-container {
    height: 100%;
    .cb-location__control {
      border-width: 0 !important;
      height: 100%;

      .cb-location__indicators {
        display: none;
      }
    }
  }
}

.cb-phone-input{
  .react-tel-input {
      .flag-dropdown {
          background-color: #363636 !important;
      }
      
      .selected-flag{
          background-color: #363636 !important;
      }
  }
  
}
