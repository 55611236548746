.cb-arrow-button {
  @apply cursor-pointer w-full flex items-center py-3;

  & span {
    @apply text-sm text-gray whitespace-nowrap mr-3 transition-all duration-300;
  }

  &.yellow span {
    @apply text-yellow;
  }

  &.white span {
    @apply text-gray;
  }

  &:hover span {
    transform: translateX(3px);
  }

  & svg * {
    @apply transition-all duration-300;
  }

  & svg path:first-child {
    fill: transparent;
  }

  &:hover svg path:last-child {
    stroke: #fff;
  }

  &:hover svg path:first-child {
    fill: var(--yellow);
  }
}
