.cb-badge {
  @apply w-20 h-5 flex items-center justify-center text-center text-2xs border border-solid rounded-xxs whitespace-nowrap;

  &.green {
    color: #04af00;
    background-color: #effff6;
    border-color: #04af00;
  }

  &.yellow {
    color: #ff9900;
    background-color: #fffcf4;
    border-color: #ff9900;
  }

  &.red {
    color: #f24c4c;
    background-color: #fff2f2;
    border-color: #f24c4c;
  }

  &.gray {
    color: #d5d5d5;
    background-color: #4b4b4b;
    border-color: #b3b3b3;
  }
}
