.cb-post-input {
  .emoji-picker {
    top: 50%;
    transform: translateY(-50%);
  }
}

.post-description {
  background-color: var(--gray-dark) !important;
  border: none !important;
  color: white;
}
