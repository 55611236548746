.cb-table {
  tr {
    @apply relative flex flex-col lg:table-row pb-4 lg:pb-0;
  }

  &.with-action {
    th:last-of-type {
      @apply absolute lg:relative top-2 lg:top-0 right-0;
    }
  }

  thead {
    @apply hidden lg:table-header-group;
  }

  th {
    @apply mt-1 lg:mt-0;
  }

  th:not(:first-of-type) {
    @apply pl-6 pr-3 md:pr-6 lg:py-4;
  }

  &:not(.with-action) {
    th:not(:first-of-type) {
      @apply pr-6;
    }
  }

  th:first-of-type {
    @apply px-6 py-4;
  }
}
