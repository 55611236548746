.cb-search-input {
  @apply flex items-center;

  & .search-input {
    @apply z-0;
  }

  & input {
    @apply w-full rounded-lg border-none shadow-search h-11 text-xs pl-12 focus:ring-0 text-gray-dark;
  }

  &.dropdown-open input {
    @apply shadow-none;
  }

  & .cb-icon {
    @apply absolute top-2/4 left-5 -translate-y-1/2 transform w-3;
  }
}
