.cb-search-bar-dropdown {
  @apply absolute top-0 left-0 w-full shadow-search rounded-lg pt-11 pb-2.5 text-xs;
  z-index: 9;
  hr {
    border-color: #f2f3f5;
  }

  & .cb-icon-search svg * {
    @apply transition duration-300;
    fill: #b3b3b3;
  }

  .cb-spinner svg * {
    fill: #fff;
  }

  & .sb-item:hover .cb-icon-search svg * {
    fill: var(--yellow);
  }
}
