.cb-spinner {
  .cb-icon svg * {
    fill: #c4c4c4;
    transition: all 0.3s;
  }

  .cb-icon.active svg * {
    fill: var(--yellow);
  }
}
