.cb-secondary-input {
  @apply h-80 overflow-hidden;

  .ql-toolbar {
    @apply border-t border-l border-r border-yellow rounded-t-md;
  }

  .ql-container {
    @apply border-b border-l border-r border-yellow rounded-b-md;
    height: calc(100% - 43.86px);
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before{
    color: var(--white-secondary) !important;
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: var(--yellow) !important;
  }

  button {
    color: var(--white-secondary) !important;
    svg {
      path,
      line {
        stroke: var(--white-secondary) !important;
      }

      rect {
        fill: var(--white-secondary) !important;
      }
    }
  }

  .ql-active button, button:hover, button.ql-active {
    color: var(--yellow) !important;
    svg {
      path,
      line {
        stroke: var(--yellow) !important;
      }

      rect {
        fill: var(--yellow) !important;
      }
    }
  }

  .ql-picker-label:hover {
    color: var(--yellow) !important;
    svg {
      polygon {
        stroke: var(--yellow) !important;
      }
    }
  }
  .ql-picker-options {
    span.ql-selected,
    .ql-active,
    span:hover {
      color: var(--yellow) !important;
    }
  }
}

@media (max-width: 767px) {
  .cb-secondary-input {
    .ql-container {
      height: calc(100% - 69.72px);
    }
  }
}
