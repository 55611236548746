.cb-avatar {
  @apply flex max-h-full items-center cursor-pointer;

  & img {
    @apply rounded-full h-11 w-11 mr-4 border-1/2 border-solid;
  }

  &.flex-row-reverse img {
    @apply mr-0 ml-4;
  }

  &.only-photo img {
    @apply mr-0;
  }

  & p {
    @apply text-gray;
  }
}
